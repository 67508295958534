
















import { computed } from '@vue/composition-api';
import '@trevoreyre/autocomplete-vue/dist/style.css';

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    id: {
      required: false,
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => {}
    },
    rounded: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return { inputValue };
  }
};
