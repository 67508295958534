var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":['summer'],"label":"Select internship cycle"},model:{value:(_vm.simpleMeetingInternshipCycle),callback:function ($$v) {_vm.simpleMeetingInternshipCycle=$$v},expression:"simpleMeetingInternshipCycle"}})]}}])}),_c('div',{staticClass:"text-center mb-8"},[_c('h2',[_vm._v("Meeting")])]),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ATextInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","label":"Meeting Name"},model:{value:(_vm.simpleMeetingName),callback:function ($$v) {_vm.simpleMeetingName=$$v},expression:"simpleMeetingName"}})]}}])}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pl-0 pr-1",attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"simpleMeetingStartDateRef",attrs:{"close-on-content-click":false,"return-value":_vm.simpleMeetingStartDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.simpleMeetingStartDate=$event},"update:return-value":function($event){_vm.simpleMeetingStartDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rounded":"","outlined":"","error-messages":errors,"label":"Start date","readonly":""},model:{value:(_vm.simpleMeetingStartDate),callback:function ($$v) {_vm.simpleMeetingStartDate=$$v},expression:"simpleMeetingStartDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.simpleMeetingStartDateRef),callback:function ($$v) {_vm.simpleMeetingStartDateRef=$$v},expression:"simpleMeetingStartDateRef"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.simpleMeetingStartDate),callback:function ($$v) {_vm.simpleMeetingStartDate=$$v},expression:"simpleMeetingStartDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.simpleMeetingStartDateRef = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.simpleMeetingStartDateRef.save(_vm.simpleMeetingStartDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"pl-1 pr-0",attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"simpleMeetingEndDateRef",attrs:{"close-on-content-click":false,"return-value":_vm.simpleMeetingEndDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.simpleMeetingEndDate=$event},"update:return-value":function($event){_vm.simpleMeetingEndDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rounded":"","outlined":"","label":"End date","readonly":"","error-messages":errors},model:{value:(_vm.simpleMeetingEndDate),callback:function ($$v) {_vm.simpleMeetingEndDate=$$v},expression:"simpleMeetingEndDate"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.simpleMeetingEndDateRef),callback:function ($$v) {_vm.simpleMeetingEndDateRef=$$v},expression:"simpleMeetingEndDateRef"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.simpleMeetingEndDate),callback:function ($$v) {_vm.simpleMeetingEndDate=$$v},expression:"simpleMeetingEndDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.simpleMeetingEndDateRef = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.simpleMeetingEndDateRef.save(_vm.simpleMeetingEndDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":_vm.simpleMeetingTimeZoneList,"label":"Time zone"},model:{value:(_vm.simpleMeetingTimeZone),callback:function ($$v) {_vm.simpleMeetingTimeZone=$$v},expression:"simpleMeetingTimeZone"}})]}}])}),[_c('v-sheet',{staticClass:"pa-5"},[_c('v-row',[_c('v-col',{staticClass:"pl-0 pr-0 pt-0"},[_vm._v("Regular Meeting ")]),_c('v-col',{staticClass:"pl-0 pr-0 pt-0"},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"color":"blue"},model:{value:(_vm.isSwitchOn),callback:function ($$v) {_vm.isSwitchOn=$$v},expression:"isSwitchOn"}})],1),_c('v-col',{staticClass:"pl-0 pr-0 pt-0"},[_vm._v(" Recurring Meeting")])],1)],1)],(_vm.isSwitchOn)?_c('div',[_c('div',{staticClass:"text-start mb-2"},[_c('h4',[_vm._v("Select recurring days per week")])]),_c('v-row',{staticClass:"mt-3 mb-8"},_vm._l((_vm.simpleMeetingWeekDays),function(item){return _c('div',{key:item,staticClass:"d-flex justify-content: center"},[_c('v-col',[_c('a-button',{staticClass:"white--text",attrs:{"id":"day","width":"10px","rounded":"","color":_vm.daysPerWeekForSimpleMeeting.includes(item) ? 'blue' : 'grey'},on:{"click":function($event){_vm.daysPerWeekForSimpleMeeting.includes(item)
                ? _vm.daysPerWeekForSimpleMeeting.splice(_vm.daysPerWeekForSimpleMeeting.indexOf(item), 1)
                : _vm.daysPerWeekForSimpleMeeting.push(item)}}},[_vm._v(_vm._s(item.split('')[0]))])],1)],1)}),0),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":['10am-11am', '11am-12pm', '12pm-1pm'],"label":"Start & end time"},model:{value:(_vm.startAndEndTime),callback:function ($$v) {_vm.startAndEndTime=$$v},expression:"startAndEndTime"}})]}}],null,false,3666394107)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":_vm.simpleMeetingRepeatTimes,"label":"Repeat every"},model:{value:(_vm.simpleMeetingRepeat),callback:function ($$v) {_vm.simpleMeetingRepeat=$$v},expression:"simpleMeetingRepeat"}})]}}],null,false,1735192848)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":_vm.simpleMeetingOccurenceList,"label":"End after"},model:{value:(_vm.endsAfter),callback:function ($$v) {_vm.endsAfter=$$v},expression:"endsAfter"}})]}}],null,false,2121096671)})],1):_c('div',[_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('ASelectInput',{attrs:{"error-messages":errors,"rounded":"","outlined":"","items":['10am-11am', '11am-12pm', '12pm-1pm'],"label":"Start & end time"},model:{value:(_vm.startAndEndTime),callback:function ($$v) {_vm.startAndEndTime=$$v},expression:"startAndEndTime"}})]}}])})],1),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mb-3",attrs:{"error-messages":errors,"rounded":"","outlined":"","label":"Description","hint":"Description"},model:{value:(_vm.simpleMeetingDescription),callback:function ($$v) {_vm.simpleMeetingDescription=$$v},expression:"simpleMeetingDescription"}})]}}])}),_c('div',{staticClass:"headline font-weight-bold"},[_vm._v("Who would you like to add to this calendar?")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-checkbox',{attrs:{"label":"Employer","value":"employer"},model:{value:(_vm.simpleMeetingSelected),callback:function ($$v) {_vm.simpleMeetingSelected=$$v},expression:"simpleMeetingSelected"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Current Interns","value":"intern"},model:{value:(_vm.simpleMeetingSelected),callback:function ($$v) {_vm.simpleMeetingSelected=$$v},expression:"simpleMeetingSelected"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }